'use client';

import useCart from '@/contexts/CartProvider';
import useToggle from '@labHooks/use-toggle';
import useLabAuthContext from '@labServices/context/LabAuthContext';
import useLabCartContext from '@labServices/context/LabCartContext';
import DialogModal from '@labtest/presentation/atoms/Modal/DialogModal';
import StateFullModal from '@labtest/presentation/atoms/Modal/StateFullModal';
import PreventDoubleClick from '@labtest/presentation/atoms/PreventDoubleClick';
import OrderSuccessModal from '@labtest/presentation/organisms/OrderSuccessModal';
import PopUpModal from '@labtest/presentation/organisms/PopUpModal';
import ModalHeader from '../ModalHeader';

import { placeLabOrder } from '@labServices/apis/lab-test-apis/order';
import { getFlatNumber, isEmptyObject } from '@labServices/utils/cmnHelper';
import { v1_checkout_cart_image } from '@labServices/utils/svg';
import { useState } from 'react';
import { MdArrowRight } from 'react-icons/md';

import type { IG_LT_OrderDetails } from '@labServices/apis/types/lab-order/type.getOrderDetails';
import type { IC_LT_PlaceLabOrderBody } from '@labServices/apis/types/lab-order/type.placeLabOrder';

import styles from './LabTestCheckoutSummary.module.scss';

interface I_Props {
	isLogged: boolean;
	setLabCheckoutData: any;
	showCheckoutModal: any;
	handleOpenNotFound: any;
	setNotAvailableRes: any;
	setShowCheckoutModal: any;
	setShowLoginModal: any;
	// setShowAddressModal: any;
	handleOpenOrderSuccessModal: any;
}

const LabTestCheckoutSummary = ({
	isLogged,
	// setLabCheckoutData,
	// showCheckoutModal,
	// handleOpenNotFound,
	// setNotAvailableRes,
	setShowCheckoutModal,
	setShowLoginModal
	// setShowAddressModal
	// handleOpenOrderSuccessModal
}: I_Props) => {
	const { setcartTotalPriceItems } = useLabAuthContext();
	const { cartData, setCartData, selectedDateTime, additionalNote, couponCode, handleGetLabCartData } =
		useLabCartContext();
	const { carts: webCart, setDrawerOpen, setShowAddressModal } = useCart();
	const cartAddress = { ...webCart?.uc_address };

	const [openNotfound, handleOpenNotFound, handleCloseNotfound] = useToggle();
	const [openPaymentStatus, handleOpenPaymentStatusModal, handleClosePaymentStatusModal] = useToggle();
	const [openOrderSuccessModal, handleOpenOrderSuccessModal, handleCloseOrderSuccessModal] = useToggle();
	const [notAvailableRes, setNotAvailableRes] = useState(null);
	const [labOrderDetails, setLabOrderDetails] = useState<Partial<IG_LT_OrderDetails>>({});

	// const fetchCheckoutData = async (e) => {
	// 	e.stopPropagation();

	// 	let res;
	// 	if (!isEmptyObject(cartAddress)) {
	// 		res = await getCheckoutData();
	// 		if (res?.success) {
	// 			setLabCheckoutData(res); // set checkout data
	// 			showCheckoutModal();
	// 			return;
	// 		}
	// 	} else {
	// 		setShowAddressModal(true);
	// 		return;
	// 	}

	// 	setNotAvailableRes(res);
	// 	handleOpenNotFound();
	// };

	// const handelScroll = (e) => {
	// 	e.stopPropagation();
	// 	if (typeof window !== 'undefined') {
	// 		const element = document.getElementById('sticky-cart-payment-summary');
	// 		if (element) {
	// 			element.scrollIntoView({
	// 				behavior: 'smooth',
	// 				block: 'center',
	// 				inline: 'nearest'
	// 			});
	// 		}
	// 	}
	// };

	const handlePlaceLabOrder = async () => {
		const checkoutData: IC_LT_PlaceLabOrderBody = {
			cartUqid: cartData?.id,
			scheduleDate: selectedDateTime?.date,
			scheduleTimeUqid: selectedDateTime?.time,
			isHardCopyRequired: cartData?.isHardCopyRequired || false,
			additionalNotes: additionalNote || '',
			priceDiscount: cartData?.priceDiscount,
			promoCode: couponCode || '',
			promoDiscount: cartData?.promoDiscount || '0',
			subtotalAmount: cartData?.subtotalAmount || '0',
			discountAmount: cartData?.discountAmount || '0',
			roundingOff: cartData?.roundingOff || '0',
			labMaterialCharge: cartData?.labMaterialCharge || '0',
			collectionConveyance: cartData?.shouldApplyHardCopyConveyance ? cartData.collectionConveyance : '0',
			hardCopyConveyance:
				cartData?.isHardCopyRequired || cartData?.shouldApplyHardCopyConveyance ? cartData.hardCopyConveyance : '0',
			totalAmount: cartData?.isHardCopyRequired ? cartData?.totalAmount : cartData?.totalAmountWithoutConveyance,
			paymentMethod: 'cod'
		};

		const res = await placeLabOrder(checkoutData);

		if (!res?.success) {
			if (res?.data && 'errorTag' in res?.data && res?.data?.errorTag === 'cart_session_expired') {
				handleGetLabCartData();
				// setTimeout(() => {
				// 	handlePlaceLabOrder();
				// }, 1000);
			}
			setNotAvailableRes(res);
			handleOpenNotFound();
			return;
		}
		setDrawerOpen(false);
		setLabOrderDetails(res?.data);
		handleOpenOrderSuccessModal();
		setcartTotalPriceItems({
			totalAmount: '0',
			itemCount: 0
		});

		// TODO: Need to ask previous vendor will te select at next time or not
		handleGetLabCartData();
		// setCartData((prev) => ({
		// 	...prev,
		// 	items: [],
		// 	totalAmount: '0',
		// 	itemCount: 0
		// }));
		// setItemInLocalStorage(cartStorageName, {});

		// Lab Test Order Purchase Event
		// LabTestOrderPurchaseEvent(data?.data);

		// if (data?.data?.paymentMethod === PaymentMethod.ONLINE) {
		// 	let popupWindowRef;
		// 	const res = await onlinePaymentForOrder(data?.data?.id);

		// 	if (res?.success) {
		// 		popupWindowRef = popupWindow(`${res?.data?.paymentUrl}`, 'Arogga Payment', window, 650, 700);
		// 		setCartData({});
		// 		monitorPopupWindow();
		// 	}
		// 	function monitorPopupWindow() {
		// 		if (popupWindowRef && !popupWindowRef.closed) {
		// 			setTimeout(monitorPopupWindow, 200);
		// 		} else {
		// 			handleOpenOrderSuccessModal();
		// 		}
		// 	}
		// 	return;
		// }
		// setCartData({});
		// setDrawerOpen(false);
		// handleOpenOrderSuccessModal();
	};

	return (
		<>
			{Number(cartData?.itemCount) > 0 && (
				<div className={styles.checkout}>
					<PreventDoubleClick
						onClick={() => {
							if (!isEmptyObject(cartData?.userLocation)) {
								handlePlaceLabOrder();
							} else {
								setShowAddressModal(true);
							}
						}}>
						<div className={`${styles.cart_checkout} cursor_pointer`}>
							<div>
								{v1_checkout_cart_image}
								<div>
									<p className='ltbody_sm__regular'>
										{cartData?.itemCount} {cartData?.itemCount > 1 ? 'Items' : 'Item'}
									</p>
									<p className='ltbody_sm__semibold'>
										৳
										{cartData?.isHardCopyRequired
											? getFlatNumber(cartData?.totalAmount)
											: getFlatNumber(cartData?.totalAmountWithoutConveyance)}
									</p>
								</div>
							</div>
							<div className={styles.cart_checkout_btn}>
								<p className='ltbody_md__semibold'>Place Order</p>
								<MdArrowRight size={24} />
							</div>
						</div>
					</PreventDoubleClick>
					;
					{/* <div
						className={`${styles.cart_checkout} cursor_pointer`}
						onClick={() => {
							if (!isEmptyObject(cartData?.userLocation)) {
								handlePlaceLabOrder();
							} else {
								setShowAddressModal(true);
							}
						}}>
						<div>
							{v1_checkout_cart_image}
							<div className=''>
								<p className='ltbody_sm__regular'>
									{cartData?.itemCount} {cartData?.itemCount > 1 ? 'Items' : 'Item'}
								</p>
								<p className='ltbody_sm__semibold'>
									৳
									{cartData?.isHardCopyRequired
										? getFlatNumber(cartData?.totalAmount)
										: getFlatNumber(cartData?.totalAmountWithoutConveyance)}
								</p>
							</div>
						</div>
						<div className={styles.cart_checkout_btn}>
							<p className='ltbody_md__semibold'>Place Order</p>
							<MdArrowRight size={24} />
						</div>
					</div> */}
				</div>
			)}

			{openPaymentStatus && (
				<StateFullModal open={openPaymentStatus} closeModal={handleClosePaymentStatusModal}>
					<PopUpModal
						modalType={
							labOrderDetails?.paymentMethod === 'online' && labOrderDetails?.paymentStatus === 'paid'
								? 'online_payment_success'
								: 'online_payment_failed'
						}
						title={
							labOrderDetails?.paymentMethod === 'online' && labOrderDetails?.paymentStatus === 'paid'
								? 'Payment Success '
								: 'OPPS...!'
						}
						message={
							labOrderDetails?.paymentMethod === 'online' && labOrderDetails?.paymentStatus === 'paid'
								? 'We have received your payment successfully.'
								: 'Your payment is not completed if you successfully paid please wait for a while.'
						}
						closeModal={() => {
							if (labOrderDetails?.paymentStatus === 'paid') {
								handleClosePaymentStatusModal();
								setTimeout(() => {
									handleOpenOrderSuccessModal();
								}, 300);
							} else {
								handleClosePaymentStatusModal();
							}
						}}
						cancelDelete={handleClosePaymentStatusModal}
						confirmDelete={handleClosePaymentStatusModal}
					/>
				</StateFullModal>
			)}

			{openNotfound && (
				<StateFullModal open={openNotfound} closeModal={handleCloseNotfound}>
					<PopUpModal
						modalType='not_available'
						message={notAvailableRes?.message}
						closeModal={handleCloseNotfound}
						cancelDelete={handleCloseNotfound}
						confirmDelete={handleCloseNotfound}
					/>
				</StateFullModal>
			)}

			{openOrderSuccessModal && (
				<DialogModal
					open={openOrderSuccessModal}
					closeModal={handleCloseOrderSuccessModal}
					overflow='overflow_auto'>
					<>
						<ModalHeader title='Payment' closeModal={handleCloseOrderSuccessModal} />
						<OrderSuccessModal
							data={labOrderDetails}
							setLabOrderDetails={setLabOrderDetails}
							serviceType='lab-test'
							title={`Congratulations!`}
							message={
								labOrderDetails?.paymentStatus === 'paid' ? (
									'Order placed and paid successfully.'
								) : (
									<>
										Order placed successfully by{' '}
										<span className='ltGreyscale700 ltbody_md__medium'>“Cash on delivery”</span>
									</>
								)
							}
							handleCloseOrderSuccessModal={handleCloseOrderSuccessModal}
							handleOpenOrderSuccessModal={handleOpenOrderSuccessModal}
							handleOpenPaymentStatusModal={handleOpenPaymentStatusModal}
							handleClosePaymentStatusModal={handleClosePaymentStatusModal}
							// closeCheckoutModal={closeModal}
						/>
					</>
				</DialogModal>
			)}
		</>
	);
};

export default LabTestCheckoutSummary;
