import { IC_EMPTY_CART } from '@/assets/icons';
import Button from '@/labtest/presentation/atoms/Button';

import { Dispatch, SetStateAction } from 'react';
import styles from './EmptyCart.module.scss';

interface I_Props {
	router: any;
	closeCart: () => void;
	setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const EmptyCart = ({ closeCart, router, setDrawerOpen }: I_Props) => {
	return (
		<div className={styles.emptyCart}>
			<div className={styles.empty_image_wrap}>
				<IC_EMPTY_CART />
			</div>
			<div className={`${styles.empty_text_container} bg-secondary100`}>
				<p className={styles.empty_text}>Your cart is empty</p>
			</div>
			<Button
				variant='bgPrimary'
				onClick={() => {
					closeCart();
					setDrawerOpen(false);
					router.push('/lab-test/tests');
				}}>
				Add Tests/Packages
			</Button>
		</div>
	);
};

export default EmptyCart;
