'use client';

import useLabCartContext from '@labServices/context/LabCartContext';

import { DateTimeScheduleSkeleton } from '@labtest/presentation/atoms/Loaders/Skeleton';
import { useEffect, useRef } from 'react';

import styles from './SampleCollectionScheduleCard.module.scss';

interface I_Props {
	page?: 'cart' | 'order-details' | 'order_success_modal';
}

const SampleCollectionScheduleCard = ({ page }: I_Props) => {
	const { dateTimeSchedules, setDateTimeSchedules, selectedDateTime, setSelectedDateTime, dateTimeLoading } =
		useLabCartContext();
	const dateRef = useRef(null);
	const timeRef = useRef(null);

	const handleScroll = (e, ref) => {
		if (e.deltaY > 0) {
			ref.current.scrollLeft += 100;
		} else {
			ref.current.scrollLeft -= 100;
		}
		e.preventDefault();
	};

	useEffect(() => {
		const dateElement = dateRef?.current;
		const timeElement = timeRef?.current;

		const dateScrollHandler = (e) => handleScroll(e, dateRef);
		const timeScrollHandler = (e) => handleScroll(e, timeRef);

		dateElement?.addEventListener('wheel', dateScrollHandler);
		timeElement?.addEventListener('wheel', timeScrollHandler);

		return () => {
			dateElement?.removeEventListener('wheel', dateScrollHandler);
			timeElement?.removeEventListener('wheel', timeScrollHandler);
		};
	}, []);

	return (
		<div className={styles.schedule_container}>
			<div className={styles.schedule_container_head}>
				<p
					className={`${page === 'cart' ? 'ltbody_sm__semibold' : 'ltbody_lg__bold'} ltGreyscale900 flex justify-between`}>
					Sample Collection Schedule
					<span className='errorBase'>*</span>
				</p>
			</div>
			<div className={`p_10 fullWidth `}>
				<h6 className={`${page === 'cart' ? 'ltbody_sm__semibold' : 'ltbody_md__semibold'} ltGreyscale900`}>
					Schedule Date:
				</h6>

				<div ref={dateRef} className={`${styles.timeSchedule_wrap} fullWidth`}>
					{dateTimeLoading && (
						<div className='flex gap-10'>
							{[...Array(10)]?.map((_, index) => <DateTimeScheduleSkeleton key={index} />)}
						</div>
					)}
					{dateTimeSchedules?.dates?.map((date) => (
						<div
							key={date?.dateValue}
							onClick={() => {
								setDateTimeSchedules((prev) => ({
									dates: [...prev?.dates],
									times: [...date?.times]
								}));
								setSelectedDateTime((prev) => ({
									...prev,
									date: date?.dateValue,
									time: date?.times[0]?.id
								}));
							}}>
							<span
								className={`
                ${styles.time_slot_btn} ${selectedDateTime?.date === date?.dateValue && styles.selected_time_slot}
                t_nowrap`}>
								{date?.title?.en}
							</span>
						</div>
					))}
				</div>

				<h6 className={`${page === 'cart' ? 'ltbody_sm__semibold' : 'ltbody_md__semibold'} ltGreyscale900 mt-10`}>
					Schedule Time:
				</h6>
				<div ref={timeRef} className={`${styles.timeSchedule_wrap} fullWidth`}>
					{dateTimeLoading && (
						<div className='flex gap-10'>
							{[...Array(10)]?.map((_, index) => <DateTimeScheduleSkeleton key={index} />)}
						</div>
					)}
					{dateTimeSchedules?.times?.map((time) => (
						<div
							key={time?.id}
							onClick={() =>
								setSelectedDateTime((prev) => ({
									...prev,
									time: time?.id
								}))
							}>
							<span
								className={`
                ${styles.time_slot_btn} ${styles.time_slot_btn} ${
							selectedDateTime?.time === time?.id && styles.selected_time_slot
						}
                  t_nowrap  `}>
								{time?.title?.en}
							</span>
						</div>
					))}
				</div>

				{/* <div className="fullWidth">
          <DatePicker
            // isClearable
            // showIcon
            selected={checkoutData?.scheduleDate || new Date()}
            minDate={new Date()}
            onChange={(date) =>
              setCheckoutData((prev) => ({ ...prev, scheduleDate: date }))
            }
            // showTimeSelect
            // timeIntervals={60}
            // dateFormat="MMMM d, yyyy h:mm aa"
            dateFormat="MMMM d, yyyy"
            customInput={
              <Dropdown
                variant={checkoutData?.scheduleDate ? '' : 'error'}
                placeHolder={'Select Schedule'}
                fullWidth
                value={checkoutData?.scheduleDate}
                iconRight={<i className="fa-solid fa-calendar-days fa-lg" />}
              />
            }
          />
        </div> */}

				{/*  <div className={`${styles.timeSchedule_wrap} fullWidth`}>
          {timeSchedules.map((schedule) => (
            <div key={schedule?.id} onClick={() => setTimeSelected(schedule)}>
              <span
                className={`
                ${styles.time_slot_btn}
                ${
                  timeSelected === schedule && styles.selected_time_slot
                } time_slot t_nowrap  `}
              >
                {schedule?.title?.en}
              </span>
            </div>
          ))}
        </div> */}
			</div>
		</div>
	);
};

export default SampleCollectionScheduleCard;
