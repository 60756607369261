import { MouseEvent, ReactNode, useState } from 'react';

interface I_Props {
	children: ReactNode;
	onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const PreventDoubleClick = ({ children, onClick }) => {
	const [disable, setDisabled] = useState<boolean>(false);

	const handleClick = (e: MouseEvent<HTMLDivElement>) => {
		if (disable) return;
		setDisabled(true);

		onClick?.(e);

		setTimeout(() => setDisabled(false), 200);
	};
	return (
		<div onClick={handleClick} className='w-full' style={{ pointerEvents: disable ? 'none' : 'auto' }}>
			{children}
		</div>
	);
};

export default PreventDoubleClick;
