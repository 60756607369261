import DiscountChip from '@/labtest/presentation/atoms/DiscountChip';
import labTestEmptyImage from '@labtest/ltassets/testCard_Empty_Image.png';
import Badge from '@labtest/presentation/atoms/Badge';
import BookTestButton from '@labtest/presentation/organisms/BookTestButton';
import Image from 'next/image';

import { IC_DELETE } from '@/assets/icons';
import { getFlatNumber } from '@labServices/utils/cmnHelper';
import { Dispatch, SetStateAction } from 'react';

import type { I_CartItems } from '@labServices/apis/types/lab-cart/type.addTestToCart';

import styles from './CartTestCard.module.scss';

interface I_Props {
	data: I_CartItems;
	removeCartItem: (id: string) => void;
	setShowLoginModal: Dispatch<SetStateAction<boolean>>;
	handleShowTests: (slug: string | I_CartItems) => void;
}

const CartTestCard = ({ data, removeCartItem, setShowLoginModal, handleShowTests }: I_Props) => {
	return (
		<div className={styles.cartTestCard}>
			<div className={styles.cartTestCard_container}>
				<div className={styles.cartTestCard_icon}>
					<Image
						src={data?.item?.bannerImage?.web || labTestEmptyImage}
						alt={data?.item?.name.en || 'test'}
						width={85}
						height={85}
						quality={100}
						style={{
							width: '85px',
							height: '85px',
							borderRadius: '6px',
							objectFit: 'cover',
							border: '1px solid #EEEFF2'
						}}
					/>
					{!data?.item?.isUnavailable && (
						<>
							{Number(data?.item?.discountPercent) > 0 && (
								<div className={styles.chip_discountPrice}>
									<DiscountChip text={`${getFlatNumber(data?.item?.discountPercent)}`} chipItem={'cart'} />
								</div>
							)}
						</>
					)}
					{data?.item?.itemType === 'package' && (
						<div className={styles.chip_package}>
							<Badge text={'Package'} variant='orange' type={'lab-cart'} />
						</div>
					)}

					{data?.item?.isUnavailable && (
						<div className={styles.unavailable_icon}>
							<div className={styles.text_wrap}>
								<span className='white'>Unavailable</span>
							</div>
						</div>
					)}
				</div>
				<div className={styles.cartTestCard_description}>
					<div className={styles.cartTestCard_top}>
						<div className={styles.cartTestCard_header}>
							<p className='ltbody_sm__medium ltGreyscale900'>{data?.item?.name?.en}</p>

							<div
								onClick={(e) => {
									e.stopPropagation();
									removeCartItem(data.item.id);
								}}
								className={`${styles.cartTestCard_icon_right} cursor_pointer`}>
								<IC_DELETE />
							</div>
						</div>
						<div className={styles.cartTestCard_body}>
							<p className='ltbody_sm__regular ltGreyscale500'>{data?.item?.subtitle?.en}</p>
							<div className={styles.cartTestCard_body_price}>
								{Number(data?.item?.discountPercent) > 0 && (
									<p className='ltbody_sm__regular ltGreyscale500 text_line_through'>
										৳{getFlatNumber(data?.subtotalAmount)}
									</p>
								)}
								<p className='ltbody_sm__semibold ltGreyscale900'>৳{getFlatNumber(data?.totalAmount)}</p>
							</div>
						</div>
					</div>

					<div className={styles.cartTestCard_bottom}>
						<div
							onClick={(e) => {
								e.stopPropagation();
								if (data?.item?.testCount > 1) handleShowTests(data);
							}}
							className={`${styles.testCard_left_btn} ${data?.item?.testCount > 1 && 'cursor_pointer text_underline'}`}>
							<p className='ltbody_sm__regular'>
								Includes {data?.itemType === 'test' ? '1 Test' : `${data?.item?.testCount} Tests`}
							</p>
						</div>
						{/* <div className='relative' onClick={(e) => handleGetTestOrPackageDetails(e)}> */}
						<div className='relative'>
							<BookTestButton
								testData={data?.item}
								testId={data.item.id}
								setShowLoginModal={setShowLoginModal}
								variant={`cart_btn`}
								isUnavailable={data?.item?.isUnavailable}
							/>
						</div>
					</div>
				</div>
			</div>

			{data?.item?.isUnavailable && (
				<div className={styles.address_error_msg}>
					<p className='errorBase'>{data?.item?.hintMessage}</p>
				</div>
			)}
		</div>
	);
};

export default CartTestCard;
