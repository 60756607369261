import styles from './TextField.module.scss';

interface I_Props {
	placeholder?: string;
	maxLength?: number;
	rows?: number;
	value?: string;
	autoFocus?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextField = ({ placeholder, maxLength, rows, value, autoFocus, onChange, ...rest }: I_Props) => {
	return (
		<div className={`${styles.textfield_wrap} ${value?.length == maxLength && styles.errorDark}`}>
			<textarea
				{...rest}
				onChange={onChange}
				autoFocus={autoFocus}
				placeholder={placeholder || 'write here...'}
				maxLength={maxLength}
				rows={rows || 4}
				className={styles.textfield}
			/>
			<p className='ltbody_sm__regular flex justify-end'>
				{value?.length}/{maxLength || '120'}
			</p>
		</div>
	);
};

export default TextField;
