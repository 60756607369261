import BookforItem from '@/labtest/presentation/atoms/BookforItem';
import Button from '@/labtest/presentation/atoms/Button';
import Chip from '@labtest/presentation/atoms/Chip';
import Popover from '@labtest/presentation/atoms/Popover';

import { getAddressIcon, isEmptyObject } from '@labServices/utils/cmnHelper';
import { useState } from 'react';
import { MdEdit, MdMoreVert } from 'react-icons/md';

import type { MouseEvent } from 'react';

import styles from './Address.module.scss';

interface I_Props {
	variant?: 'primary' | 'secondary';
	onClick?: (data: any) => void;
	handleMakeDefault?: (data: any) => void;
	handleEdit?: (data: any) => void;
	handleDelete?: (data: any) => void;
	data: any;
	defaultAddress?: string;
	editable?: boolean;
	changeable?: boolean;
	setShowAddressModal: (value: boolean) => void;
	children?: React.ReactNode;
}

const Address = ({
	variant,
	onClick,
	handleMakeDefault,
	handleEdit,
	handleDelete,
	data,
	defaultAddress,
	editable = false,
	changeable = false,
	setShowAddressModal,

	children
}: I_Props) => {
	const [open, setOpen] = useState(false);
	const showAddressOption = (e: MouseEvent<SVGAElement>) => {
		e.stopPropagation();
		setOpen(true);
	};
	const closeAddressOption = () => {
		setOpen(false);
	};

	// console.log('data:', data);

	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				onClick?.(data);
			}}
			className={styles.address}>
			<div className={`${styles.address_head} ${variant ? styles[variant] : ''}`}>
				<div className='flex fullWidth justify-between mr5'>
					<p className='ltbody_sm__semibold ltGreyscale900'>
						{data?.ul_type ? `${data?.ul_type} Address` : `Shipping Address`}
					</p>
					{data?.ul_default ? <Chip text={'Default'} textSize='sm' variant='bgPrimary' color='clWhite' /> : ''}
				</div>

				{editable && (
					<div className='relative'>
						<MdMoreVert onClick={showAddressOption} />
						<Popover open={open} handleClose={closeAddressOption}>
							<BookforItem
								onClick={(e) => {
									e.stopPropagation();
									handleEdit(data);
									closeAddressOption();
								}}>
								Edit
							</BookforItem>
							<BookforItem
								onClick={(e) => {
									e.stopPropagation();
									handleMakeDefault(data);
									closeAddressOption();
								}}>
								Make Default
							</BookforItem>
							<BookforItem
								onClick={(e) => {
									e.stopPropagation();
									handleDelete(data);
									closeAddressOption();
								}}>
								Delete
							</BookforItem>
						</Popover>
					</div>
				)}

				{changeable && (
					<span onClick={setShowAddressModal}>
						<p className='ltbody_sm__medium'>Change</p>
						<MdEdit />
					</span>
				)}
			</div>
			<>
				{!isEmptyObject(data?.ul_location) ? (
					<div className={styles.address_info}>
						<div className={styles.address_text}>
							<div className={styles.icon_container}>{getAddressIcon(data?.ul_type)}</div>
							<div className='flex-column gap-3'>
								<p className='ltbody_sm__regular ltGreyscale600'>{data?.ul_name || 'N/A'}</p>
								<p className='ltbody_sm__regular ltGreyscale600'>{data?.ul_mobile || 'N/A'}</p>
								<p className='ltbody_sm__regular ltGreyscale600'>
									{data?.ul_location ? data?.ul_location : defaultAddress || 'N/A'}
								</p>
							</div>
						</div>
						{children}
					</div>
				) : (
					<div className='p_10'>
						<p className='mb10 ltGreyscale500'>You {"haven't"} added any address yet.</p>
						<Button
							variant='bgPrimaryOp_01'
							color='clPrimary'
							textSize='ltbody_md__semibold'
							fullWidth
							onClick={() => {
								setShowAddressModal(true);
							}}>
							Add New Address
						</Button>
					</div>
				)}
			</>
		</div>
	);
};

export default Address;
