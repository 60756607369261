import AddressModal from '@/components/organisms/AddressModal';
import useCart from '@/contexts/CartProvider';
import useLoginModel from '@/contexts/LoginModelProvider';
import Button from '@/labtest/presentation/atoms/Button';
import useLabAuthContext from '@labServices/context/LabAuthContext';
import useLabCartContext from '@labServices/context/LabCartContext';
import useToggle from '@labServices/hooks/use-toggle';
import Checkbox from '@labtest/presentation/atoms/Checkbox';
import Line from '@labtest/presentation/atoms/Line';
import SmallSpinner from '@labtest/presentation/atoms/Loaders/Spinners/smallSpinner';
import StateFullModal from '@labtest/presentation/atoms/Modal/StateFullModal';
import SideDrawer from '@labtest/presentation/atoms/SideDrawer';
import TextField from '@labtest/presentation/atoms/TextField';
import ToastNote from '@labtest/presentation/atoms/ToastNote';
import Address from '@labtest/presentation/molecules/Address';
import CartTestCard from '@labtest/presentation/molecules/CartTestCard';
import LabTestPaymentDetailsCard from '@labtest/presentation/molecules/LabTestPaymentDetailsCard';
import ModalHeader from '@labtest/presentation/molecules/ModalHeader';
import SampleCollectionScheduleCard from '@labtest/presentation/molecules/SampleCollectionScheduleCard';
import EmptyCart from '@labtest/presentation/organisms/EmptyCart';
import PackageDetailsDrawer from '../PackageDetailsDrawer';
import SwitchLabVendor from '../SwitchLabVendor';

import { cartStorageName } from '@/services/ecommerce/apis/config';
import { isEmptyArray } from '@labServices/utils/cmnHelper';
import { getItemFromLocalStorage } from '@labServices/utils/session';
import { CartTestSkeleton } from '@labtest/presentation/atoms/Loaders/Skeleton';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { MdEdit, MdInfo } from 'react-icons/md';

import type { I_CartItems } from '@labServices/apis/types/lab-cart/type.addTestToCart';

import styles from './LabCart.module.scss';

interface I_Props {
	closeCart: () => void;
}

const LabCart = ({ closeCart }: I_Props) => {
	const { userData } = useLabAuthContext();
	const { carts: webCart, setDrawerOpen } = useCart();
	const [openSwitchVendor, handleOpenSwitchVendorModal, handleCloseSwitchVendorModal] = useToggle();
	const {
		cartData,
		labVendors,
		removeLabTestCartItem,
		loading,
		hardCopyLoading,
		additionalNote,
		setAdditionalNote,
		handleUpdateHardCopyConveyance
	} = useLabCartContext();
	const { setShowLoginModal } = useLoginModel();

	const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
	const [selectedTest, setselectedTest] = useState<string | Partial<I_CartItems>>('');
	const [showMoreTests, setshowMoreTests] = useState<boolean>(false);

	const router = useRouter();
	const cartObj = getItemFromLocalStorage(cartStorageName);

	//TODO: Need to check if this is required

	// useEffect(() => {
	// 	if (!isEmptyArray(cartData?.items)) {
	// 		handleGetLabCartData();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [webCart?.uc_address?.ul_location]);

	const addMoreTest = () => {
		setDrawerOpen(false);
		router.push('/lab-test/tests');
	};

	const handleShowTests = (slug) => {
		setselectedTest(slug);
		setshowMoreTests(true);
	};

	const handleCloseTests = () => {
		setselectedTest('');
		setshowMoreTests(false);
	};

	if (isEmptyArray(cartData?.items) || isEmptyArray(cartObj?.items)) {
		return <EmptyCart closeCart={closeCart} router={router} setDrawerOpen={setDrawerOpen} />;
	}

	return (
		<>
			<div className={styles.cart_wrap}>
				<div className={styles.cart_testcards_wrap}>
					<div className='p-10'>
						<ToastNote
							variant='bgGhostWhite'
							color='ltGreyscale700'
							icon={<MdInfo size={16} color='var(--ltBlue)' />}>
							<div className='flex justify-between'>
								<p className='ltbody_sm__regular flex gap-4'>
									<span className='t_nowrap'>Lab: </span>
									<span className='ltbody_sm__semibold singleLine_ellipsis'>{cartData?.vendor?.name?.en}</span>
								</p>
								<div
									className='ltbody_sm__regular flex gap-4 cursor_pointer ml-5'
									onClick={handleOpenSwitchVendorModal}>
									<span className='ltBlue'>Change</span>
									<MdEdit size={16} color='var(--ltBlue)' />
								</div>
							</div>
						</ToastNote>
					</div>
					<Line />
					{loading &&
						cartData?.items?.map((_, index) => (
							<div className='border-b1-grey200 rounded-10' key={index}>
								<CartTestSkeleton />
							</div>
						))}
					{!loading &&
						!isEmptyArray(cartData?.items && cartObj?.items) &&
						cartData?.items?.map((item, index) => (
							<>
								<div
									className='cursor_pointer'
									key={item?.item?.id}
									onClick={() => {
										setDrawerOpen(false);
										router.push(`/lab-test/tests/${item?.item?.slug}`);
									}}>
									<CartTestCard
										data={item}
										removeCartItem={removeLabTestCartItem}
										setShowLoginModal={setShowLoginModal}
										handleShowTests={handleShowTests}
									/>
								</div>
								{index !== cartData?.items.length - 1 && <Line />}
							</>
						))}
				</div>
				{isEmptyArray(cartData?.items) && <p className='ltbody_lg__bold mb-20'>No tests added in the cart yet!</p>}

				{cartData?.items?.length < cartData?.maxItemCount && (
					<Button
						variant='bgPrimaryOp_01'
						color='clPrimary'
						textSize='ltbody_md__semibold'
						fullWidth
						onClick={addMoreTest}>
						Add More Tests
					</Button>
				)}

				{/* <div className={`${styles.popular_diagnostic_image_wrap} mt-20`}>
					<p className='ltbody_md__semibold ltGreyscale900 mb-10'>Trusted Lab Partner:</p>

					<div>{PopularDiagnosticCentreImage()}</div>
				</div> */}

				<div className={styles.order_address_wrap}>
					{/* {loading && (
            <div className={styles.spinner_wrap}>
              <Spinner size='sm' />
            </div>
          )} */}
					{/* {!isEmptyObject(webCart?.uc_address) && ( */}
					<Address
						data={webCart?.uc_address || cartData?.userLocation || {}}
						defaultAddress={userData?.defaultAddress}
						changeable={true}
						setShowAddressModal={setShowAddressModal}>
						{/* Additional Information */}
						<div className={styles.textfeild}>
							<TextField
								value={additionalNote}
								onChange={(e) => setAdditionalNote(e.target.value)}
								maxLength={120}
								placeholder='Additional Info (If any)'
								rows={2}
							/>
						</div>
					</Address>
					{/* )} */}
				</div>

				{/* Sample Collection */}
				<SampleCollectionScheduleCard page='cart' />

				<div className={styles.cart_checkout_hardcopy}>
					<div className={styles.cart_checkout_hardcopy_head}>
						<div className={styles.cart_checkout_hardcopy_checkbox}>
							{hardCopyLoading ? (
								<SmallSpinner />
							) : (
								<Checkbox
									checked={cartData?.isHardCopyRequired}
									size={'sm'}
									onChange={handleUpdateHardCopyConveyance}
								/>
							)}
							<p className='ltbody_sm__semibold'>Hard Copy of Report</p>
						</div>
						{/* <p className='ltbody_lg__semibold'>৳{cartData?.hardCopyConveyance}</p> */}
					</div>
					<p className='ltbody_sm__regular'>
						Report wil be delivered within 3-4 working days. Hard Copy Conveyance are non-refundable once the
						reports have been dispatched.
					</p>
				</div>

				<div className={styles.paymentDetails}>
					<LabTestPaymentDetailsCard data={cartData} page='cart' />
				</div>
			</div>

			{selectedTest && (
				<SideDrawer title='Package Details' open={showMoreTests} onClose={() => setshowMoreTests(false)}>
					<PackageDetailsDrawer data={selectedTest} onClose={() => setshowMoreTests(false)} />
				</SideDrawer>
			)}

			{openSwitchVendor && (
				<StateFullModal open={openSwitchVendor} closeModal={handleCloseSwitchVendorModal}>
					<>
						<ModalHeader title='Change Lab' closeModal={handleCloseSwitchVendorModal} />
						<SwitchLabVendor
							type='cart'
							data={labVendors}
							handleCloseSwitchVendorModal={handleCloseSwitchVendorModal}
						/>
					</>
				</StateFullModal>
			)}

			{showAddressModal && <AddressModal isOpen={showAddressModal} setIsOpen={setShowAddressModal} hover={true} />}

			{/* <DialogModal open={openDialogModal} closeModal={handleCloseDialogModal}>
				<ModalHeader title={'Sample Collection Address'} closeModal={handleCloseDialogModal} />
				{!userAddress && (
					<SampleCollectionAddress handleModalClose={handleCloseDialogModal} setRefetch={setRefetch} />
				)}
			</DialogModal> */}
		</>
	);
};

export default LabCart;
