import { RotatingLines } from 'react-loader-spinner';

const SmallSpinner = () => {
	return (
		<RotatingLines
			visible={true}
			width='20'
			height='21'
			strokeWidth='5'
			animationDuration='0.75'
			ariaLabel='rotating-lines-loading'
		/>
	);
};

export default SmallSpinner;
