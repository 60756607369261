import styles from './Checkbox.module.scss';

interface I_Props {
	checked: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	size: 'sm' | 'md';
}

const Checkbox = ({ checked, onChange, size, ...rest }: I_Props) => {
	return (
		<div className={styles.checkboxOverride}>
			<input
				{...rest}
				className={styles.checkbox}
				checked={checked}
				onChange={onChange}
				type='checkbox'
				id='checkboxInputOverride'
			/>
			<label className={`${styles.label} ${styles[size]}`} htmlFor='checkboxInputOverride'></label>
		</div>
	);
};

export default Checkbox;
