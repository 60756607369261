'use client';

import useCart from '@/contexts/CartProvider';
import useLabCartContext from '@labServices/context/LabCartContext';
import Button from '@labtest/presentation/atoms/Button';
import SelectLabVendorCard from '@labtest/presentation/molecules/SelectLabVendorCard';

import { updateLabCartVendor } from '@labServices/apis/lab-test-apis/cart';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import type { IG_LT_LabVendor } from '@labServices/apis/types/lab-vendor/type.getLabVendor';

import styles from './SwitchLabVendor.module.scss';

interface I_Props {
	data: IG_LT_LabVendor[];
	type: 'cart';
	handleCloseSwitchVendorModal?: () => void;
}

const SwitchLabVendor = ({ data, type, handleCloseSwitchVendorModal }: I_Props) => {
	const { setDrawerOpen } = useCart();
	const { cartData, setCartData, handleGetLabCartData } = useLabCartContext();
	const [selectedVendorId, setSelectedVendorId] = useState<string>('');

	const handleUpdateCartVendor = async () => {
		const res = await updateLabCartVendor(selectedVendorId);

		if (res?.success) {
			toast.success('Vendor updated successfully');
			handleCloseSwitchVendorModal();
			setDrawerOpen(false);
			setCartData((prev) => ({ ...prev, ...res?.data }));
			// handleGetLabCartData();
		}
	};

	useEffect(() => {
		setSelectedVendorId(cartData?.vendor?.id);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.switchLabVendor}>
			<div className={styles.vendorLists}>
				{/* [
						...data?.filter((labVendor) => labVendor?.id === cartData?.vendor?.id),
						...data?.filter((labVendor) => labVendor?.id !== cartData?.vendor?.id)
					] */}
				{data &&
					data
						.sort((a, b) => (a?.id === cartData?.vendor?.id ? -1 : 1))
						.map((labVendor) => (
							<SelectLabVendorCard
								key={labVendor?.id}
								cartVendorId={cartData?.vendor?.tag}
								data={labVendor}
								selectedVendorId={selectedVendorId || cartData?.vendor?.id}
								setSelectedVendorId={setSelectedVendorId}
								vendorStatus={labVendor?.status as 'active' | 'inactive'}
								type={type}
								// currentLab={labVendor?.id === cartData?.vendor?.id}
							/>
						))}
			</div>
			<div className={styles.action_btns}>
				{selectedVendorId !== cartData?.vendor?.id ? (
					<Button variant='bgPrimary' size='fullWidth' onClick={handleUpdateCartVendor}>
						Confirm
					</Button>
				) : (
					<Button disabled size='fullWidth' onClick={handleUpdateCartVendor}>
						Confirm
					</Button>
				)}
			</div>
		</div>
	);
};

export default SwitchLabVendor;
